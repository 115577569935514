<template>
    <tr>
      <td class="text-start">{{ originServerName ?? 'Sem nome' }}</td>
      <td class="text-start">{{ name ?? 'Sem nome' }}</td>
      <td class="text-start">{{ email ?? 'Sem email' }}</td>
      <td class="text-start">{{ groupName ?? 'Não definido' }}</td>
    </tr>
</template>


<script>
export default {
  props: {
    id: String,
    username: String,
    name: String,
    userGroup: String,
    email: String,
    originServerId: Number,
    originServerIdSecondary: Number,
    originServerName: String,
    passwordPristine: Boolean,
    userType: Number,
    picture: String,
    pushSubscription: String,
    notificationsEnabled: Boolean,
    allowedFloors: Array,
    standing: Boolean,
    deviceVendor: String,
    device: String,
    groupName: String,
    deviceOSVersion: String,
    deviceId: String,
    createdAt: Date,
    updatedAt: Date
    }
}
</script>

<style scoped>

td {
    background-color: aliceblue;
    cursor: pointer;
}
</style>
