<template>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ selectedUser.name ?? 'Sem Nome' }} - {{ selectedUser.email ?? 'Sem E-mail'}}</h5>
      </div>
      <div class="modal-body">
        <h5>{{ selectedUser.email }}</h5>
        <h5>{{ selectedUser.id }}</h5>
        <h5>{{ selectedUser.username }}</h5>
        <h5>{{ selectedUser.userGroup }}</h5>
        <h5>{{ selectedUser.originServerIdSecondary }}</h5>
        <h5>Condomínio: {{ selectedUser.originServerName }}</h5>
        <h5>Status senha: {{ selectedUser.passwordPristine ? 'Alterada' : 'Inicial' }}</h5>
        <h5>Tipo: {{ selectedUser.userType }}</h5>
        <h5>Status notificação: {{ selectedUser.notificationsEnabled ? 'Ativadas' : 'Desativadas'}}</h5>

    id: String,
    username: String,
    name: String,
    userGroup: String,
    email: String,
    originServerId: Number,
    originServerIdSecondary: Number,
    originServerName: String,
    passwordPristine: Boolean,
    userType: Number,
    picture: String,
    pushSubscription: String,
    notificationsEnabled: Boolean,
    allowedFloors: Array,
    standing: Boolean,
    deviceVendor: String,
    device: String,
    deviceOSVersion: String,
    deviceId: String,
    createdAt: Date,
    updatedAt: Date

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close">Fechar</button>
      </div>
    </div>
  </div>
</div>
<body v-if="loaded" class="container">
  <h1 class="text-start mt-5">Usuários:</h1>
  <p class="text-start mt-5">Filtrar por:</p>
  <div class="text-start mb-3">
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" :checked="option0" @change="option0 = !option0">
      <label class="form-check-label" for="inlineCheckbox2">Host</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" :checked="option1" @change="option1 = !option1">
      <label class="form-check-label" for="inlineCheckbox2">Nome</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" :checked="option2" @change="option2 = !option2">
      <label class="form-check-label" for="inlineCheckbox1">E-mail</label>
    </div>
    <div class="form-check form-check-inline">
      <input class="form-check-input" type="checkbox" :checked="option3" @change="option3 = !option3">
      <label class="form-check-label" for="inlineCheckbox2">Grupo</label>
    </div>
  </div>
    <div v-if="option0" class="input-group mb-3">
      <div class="input-group mb-1">
        <span class="input-group-text" id="basic-addon3">{{ fancyOptions['originServerName'] }}</span>
        <input type="text" v-model="opcoes['originServerName']" class="form-control">
      </div>
    </div>
    <div v-if="option1" class="input-group mb-3">
      <div class="input-group mb-1">
        <span class="input-group-text" id="basic-addon3">{{ fancyOptions['name'] }}</span>
        <input type="text" v-model="opcoes['name']" class="form-control">
      </div>
    </div>
    <div v-if="option2" class="input-group mb-3">
      <div class="input-group mb-1">
        <span class="input-group-text" id="basic-addon3">{{ fancyOptions['email'] }}</span>
        <input type="text" v-model="opcoes['email']" class="form-control">
      </div>
    </div>
    <div v-if="option3" class="input-group mb-3">
      <div class="input-group mb-1">
        <span class="input-group-text" id="basic-addon3">{{ fancyOptions['group'] }}</span>
        <input type="text" v-model="opcoes['group']" class="form-control">
      </div>
    </div>
    <p v-if="search != ''" class="text-start">Mostrando {{ filteredUsers.length }} resultados</p>
  <div class="body-background mt-3 mb-5 list-group list-group-flush">
    <table class="table">
      <thead>
        <tr>
          <th class="text-start" @click="sortByHost()" scope="col">Host</th>
          <th class="text-start" @click="sortByName()" scope="col">Nome</th>
          <th class="text-start" @click="sortByEmail()" scope="col">E-mail</th>
          <th class="text-start" @click="sortByGroup()" scope="col">Grupo</th>
        </tr>
      </thead>
      <tbody>
        <usercard @click="selectUser(user)"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          v-for="user in filteredUsers" 
          :key="user.id" :name="user.name" 
          :email="user.email" 
          :originServerName="user.originServerName" 
          :userGroup="user.userGroup"
          :groupName="user.group ?? ''"
          />
      </tbody>
    </table>
  </div>
</body>
</template>

<script>
import usercard from '../components/userCard.vue'

import requests from '../plugins/requests.js'

const removeNull = (schools, oldData, newData) => {
    return schools.map(item => {
        var temp = Object.assign({}, item);
        if (temp.name === oldData) {
            temp.name = newData;
        }
        return temp;
    });
}

export default {
  components: {
    usercard,
  },
  data(){
  return {
    option0: false,
    option1: false,
    option2: false,
    option3: false,
      search : '',
      users : [],
      groups : [],
      selectedUser: {},
      opcoes: {
          originServerName: '',
          email: '',
          name: '',
          group: ''
      },
      fancyOptions: {
          originServerName: 'Host',
          email: 'E-mail',
          name: 'Nome',
          group: 'Grupo'
      },
      loaded: false
    }
  },
  methods: {
    changeOption0() {this.option0 = !this.option0},
    selectUser(user) { this.selectedUser = user },
    sortByName() {
      this.users = this.users.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
        });
    },
    sortByHost() {
      this.users = this.users.sort(function (a, b) {
        if (a.originServerName > b.originServerName) {
          return 1;
        }
        if (a.originServerName < b.originServerName) {
          return -1;
        }
        return 0;
        });
    },
    sortByEmail() {
      this.users = this.users.sort(function (a, b) {
        if (a.email > b.email) {
          return 1;
        }
        if (a.email < b.email) {
          return -1;
        }
        return 0;
        });
    },   
    sortByGroup() {
      this.users = this.users.sort(function (a, b) {
        if (a.group > b.group) {
          return 1;
        }
        if (a.group < b.group) {
          return -1;
        }
        return 0;
        });
    }
  },  
  computed: {
    filteredUsers() {
      var that = this

      return this.users.filter(function (user) {
        return user.name.toLowerCase().indexOf(((that.option1 == true) ? that.opcoes.name.toLowerCase() : '')) > -1
      }).filter(function (user) {
        return user['group' ?? 'name'].toLowerCase().indexOf(((that.option3 == true) ? that.opcoes.group.toLowerCase() : '')) > -1
      }).filter(function (user) {
        return user.email.toLowerCase().indexOf(((that.option2 == true) ? that.opcoes.email.toLowerCase() : '')) > -1
      }).filter(function (user) {
        return user.originServerName.toLowerCase().indexOf(((that.option0 == true) ? that.opcoes.originServerName.toLowerCase() : '')) > -1
      })
    }
  },
  created(){
    requests.users()
    .then(res => this.users = res.data )
      .then(() => this.users = removeNull(this.users, null, 'Não definido'))
        .then(() => { requests.groups()
            .then(res => { this.groups = res.data; this.groups.push({
              id: 0,
              hostname: "Sem nome",
              name: "sem nome",
              originServerId: "000",
              createdAt: Date.now(),
              updatedAt: Date.now(),
              })
              this.users.map(user => Object.assign(user, {group: (this.groups.find(group => group.id.toString() == (user.userGroup ?? 0)).hostname)}))
              this.loaded = true
         })
        })
  }
}
</script>

<style scoped>

  th {
    cursor: pointer;
  }

  .body-background {
  padding: 30px;
  border-radius: 5px;
  background-color: aliceblue;
  
}
</style>

